<template>
  <div class="growthview">
    <canvas ref="canvasRef" class="growthcanvas" />
  </div>
</template>

<!-- eslint-disable no-unused-vars -->
<!-- eslint-disable no-constant-condition -->
<script>
import { ref, onMounted } from 'vue'
import {
  GrowthModel,
  weeksPerMonth,
  weeksPerYear,
  weeksPerQuarter
} from '@/utils/growthmodel/growthmodel'
import BoxLayout from '@/utils/web/BoxLayout'
import HitDetector from '@/utils/web/HitDetector'
import canvasutils from '@/utils/web/canvasutils'

export default {
  setup () {
    const canvasRef = ref(null)
    /**
     * Formatters
     */
    function fmtTime (week, units, digits) {
      if (!digits) digits = 0
      switch (units) {
        case 'week':
          return 'week ' + week.toFixed(digits)
        case 'month':
          return 'month ' + (week / weeksPerMonth).toFixed(digits)
        case 'quarter':
          return 'Q' + (week / weeksPerQuarter).toFixed(digits)
        case 'year':
          return 'year ' + (week / weeksPerYear).toFixed(digits)
        default:
          throw new Error('unknown units ' + units)
      }
    }

    function fmtPercentage (v, digits, stz) {
      v *= 100
      if (v !== 0) {
        digits = Math.max(
          0,
          digits - Math.ceil(Math.log(Math.abs(v)) / Math.log(10) + 0.001)
        )
      }
      let asFixed = v.toFixed(digits)
      if (stz) asFixed = asFixed.replace(/0+$/, '')
      return asFixed + '%'
    }

    function fmtGrowth (weeklyGrowth, units, digits, showUnits) {
      if (!digits) digits = 0
      switch (units) {
        case 'week':
          return (
            fmtPercentage(weeklyGrowth, digits) + (showUnits ? ' weekly' : '')
          )
        case 'month':
          return (
            fmtPercentage(
              Math.exp(Math.log(1 + weeklyGrowth) * weeksPerMonth) - 1,
              digits
            ) + (showUnits ? ' monthly' : '')
          )
        case 'quarter':
          return (
            fmtPercentage(
              Math.exp(Math.log(1 + weeklyGrowth) * weeksPerQuarter) - 1,
              digits
            ) + (showUnits ? ' quarterly' : '')
          )
        case 'year':
          return (
            fmtPercentage(
              Math.exp(Math.log(1 + weeklyGrowth) * weeksPerYear) - 1,
              digits
            ) + (showUnits ? ' yearly' : '')
          )
        default:
          throw new Error('unknown units ' + units)
      }
    }

    function fmtFlow (weeklyFlow, units, digits, showUnits) {
      if (!digits) digits = 0
      switch (units) {
        case 'week':
          return fmtMoney(weeklyFlow, digits) + (showUnits ? ' weekly' : '')
        case 'month':
          return (
            fmtMoney(weeklyFlow * weeksPerMonth, digits) +
            (showUnits ? ' monthly' : '')
          )
        case 'quarter':
          return (
            fmtMoney(weeklyFlow * weeksPerQuarter, digits) +
            (showUnits ? ' quarterly' : '')
          )
        case 'year':
          return (
            fmtMoney(weeklyFlow * weeksPerYear, digits) +
            (showUnits ? ' yearly' : '')
          )
        default:
          throw new Error('unknown units ' + units)
      }
    }

    function fmtUnits (units) {
      switch (units) {
        case 'week':
          return 'Weekly'
        case 'month':
          return 'Monthly'
        case 'quarter':
          return 'Quarterly'
        case 'year':
          return 'Yearly'
        default:
          throw new Error('unknown units ' + units)
      }
    }

    function fmtMoney (v, digits) {
      let suffix = ''
      if (v >= 100e12) {
        // Don't show silly numbers
        return 'Unreasonable'
      }
      if (v >= 0.999e12) {
        suffix = 'T'
        v /= 1e12
      } else if (v >= 0.999e9) {
        suffix = 'B'
        v /= 1e9
      } else if (v >= 0.999e6) {
        suffix = 'M'
        v /= 1e6
      } else if (v >= 1e4) {
        suffix = 'k'
        v /= 1e3
      } else {
        suffix = ''
      }
      if (v >= 1000) {
        digits = Math.max(0, digits - 4)
      } else if (v >= 100) {
        digits = Math.max(0, digits - 3)
      } else if (v >= 10) {
        digits = Math.max(0, digits - 2)
      } else if (v >= 1) {
        digits = Math.max(0, digits - 1)
      }

      return '$' + v.toFixed(digits) + suffix
    }

    function getGrowthRates (units) {
      switch (units) {
        case 'week':
          return [
            0, 0.005, 0.01, 0.015, 0.02, 0.025, 0.03, 0.04, 0.05, 0.06, 0.07,
            0.08, 0.09, 0.1
          ]
        case 'month':
          return [
            0, 0.025, 0.05, 0.075, 0.1, 0.15, 0.2, 0.25, 0.3, 0.4, 0.5
          ].map((mgr) => {
            return Math.exp(Math.log(1 + mgr) / weeksPerMonth) - 1
          })
        case 'quarter':
          return [0, 0.1, 0.15, 0.2, 0.25, 0.3, 0.4, 0.5, 1.0, 2.5].map(
            (mgr) => {
              return Math.exp(Math.log(1 + mgr) / weeksPerQuarter) - 1
            }
          )
        case 'year':
          return [0, 0.25, 0.5, 1.0, 2.5, 5.0, 10.0, 25.0, 50.0, 100.0].map(
            (ygr) => {
              return Math.exp(Math.log(1 + ygr) / weeksPerYear) - 1
            }
          )
        default:
          throw new Error('unknown units ' + units)
      }
    }

    function getFlows (units) {
      switch (units) {
        case 'week':
          return [
            100, 300, 1000, 3000, 10000, 30000, 100000, 300000, 1e6, 3e6, 1e7
          ]
        case 'month':
          return [
            300, 1000, 3000, 10000, 30000, 100000, 300000, 1e6, 3e6, 1e7, 3e7
          ].map((mfr) => {
            return mfr / weeksPerMonth
          })
        case 'quarter':
          return [
            1000, 3000, 10000, 30000, 100000, 300000, 1e6, 3e6, 1e7, 3e7, 1e8
          ].map((mfr) => {
            return mfr / weeksPerQuarter
          })
        case 'year':
          return [
            3000, 10000, 30000, 100000, 300000, 1e6, 3e6, 1e7, 3e7, 1e8, 3e8,
            1e9
          ].map((yfr) => {
            return yfr / weeksPerYear
          })
        default:
          throw new Error('unknown units ' + units)
      }
    }

    /*
  Canvas drawing helpers
*/

    function drawDragHandle (ctx, cX, cY, radius, style) {
      ctx.beginPath()

      ctx.lineWidth = radius / 4
      switch (style) {
        case 'exp':
          ctx.fillStyle = canvasutils.mkShinyPattern(
            ctx,
            cY - radius,
            cX + radius,
            cY + radius,
            cX - radius,
            '#b20000',
            '#ff0000'
          )
          break
        case 'rev':
          ctx.fillStyle = canvasutils.mkShinyPattern(
            ctx,
            cY - radius,
            cX + radius,
            cY + radius,
            cX - radius,
            '#008e00',
            '#00cc00'
          )
          break
        default:
          break
      }
      ctx.arc(cX, cY, radius, 0, Math.PI * 2)
      ctx.fill()

      switch (style) {
        case 'exp':
          ctx.strokeStyle = '#b20000'
          break
        case 'rev':
          ctx.strokeStyle = '#007a00'
          break
        default:
          break
      }
      ctx.stroke()
      ctx.beginPath()
      switch (style) {
        case 'exp':
          ctx.fillStyle = '#ff0000'
          ctx.arc(cX, cY, 0.2 * radius, 0, 2 * Math.PI)
          ctx.fill()
          break
        case 'rev':
          ctx.fillStyle = '#00ff00'
          ctx.arc(cX, cY, 0.2 * radius, 0, 2 * Math.PI)
          ctx.fill()
          break
        default:
          break
      }
    }

    class BaseCanvas {
      constructor (canvas) {
        this.canvas = canvas
        this.hd = new HitDetector()
        this.setupHandlers()
        this.canvasStyle = window.getComputedStyle(canvas)
      }

      teardown () {}

      animate (dt) {}

      setupHandlers () {
        const canvas = this.canvas

        const eventOffsets = (ev) => {
          if (ev.offsetX !== undefined) {
            return { x: ev.offsetX, y: ev.offsetY }
          }
          // Firefox doesn't have offsetX, you have to work from page coordinates
          if (ev.pageX !== undefined) {
            return {
              x: ev.pageX - this.offset().left,
              y: ev.pageY - this.offset().top
            }
          }
          // jQuery doesn't copy pageX when the event is 'wheel'
          if (ev.originalEvent.pageX !== undefined) {
            return {
              x: ev.originalEvent.pageX - this.offset().left,
              y: ev.originalEvent.pageY - this.offset().top
            }
          }
          return null
        }
        const eventDeltas = (ev) => {
          if (ev.deltaX !== undefined) {
            return { x: ev.deltaX, y: ev.deltaY }
          }
          if (ev.originalEvent && ev.originalEvent.deltaX !== undefined) {
            return { x: ev.originalEvent.deltaX, y: ev.originalEvent.deltaY }
          }
          return { x: 0, y: 0 }
        }

        let prevDrawTime = 0.0
        const redrawThis = (t) => {
          let dt = t - prevDrawTime
          prevDrawTime = t
          dt = Math.max(0, Math.min(0.2, dt))
          this.animate(dt)
          this.redrawCanvas()
        }

        canvas.addEventListener('wheel', (ev) => {
          const hd = this.hd
          const md = eventOffsets(ev)
          if (!md) return
          const action = hd.findScroll(md.x, md.y)
          if (action && action.onScroll) {
            const deltas = eventDeltas(ev)
            if (deltas) {
              const scrollRate = Math.min(
                15,
                Math.max(Math.abs(deltas.x), Math.abs(deltas.y))
              )
              action.onScroll(deltas.x * scrollRate, deltas.y * scrollRate)
              requestAnimationFrame(redrawThis)
            }
          }
        })

        canvas.addEventListener('mousedown', (ev) => {
          const hd = this.hd
          const md = eventOffsets(ev)
          const action = hd.find(md.x, md.y) || hd.defaultActions
          if (action) {
            if (
              action.onDown ||
              action.onClick ||
              action.onUp ||
              action.drawDown ||
              action.drawCustom
            ) {
              hd.buttonDown = true
              hd.mdX = md.x
              hd.mdY = md.y
              hd.shiftKey = ev.shiftKey
              hd.altKey = ev.altKey
              hd.ctrlKey = ev.ctrlKey
              if (action.onDown) {
                action.onDown(hd.mdX, hd.mdY, ev)
              }
            }
          }
          requestAnimationFrame(redrawThis)
        })

        canvas.addEventListener(
          'mousemove',
          (ev) => {
            const hd = this.hd
            const md = eventOffsets(ev)
            const action = hd.find(md.x, md.y)
            if (
              hd.buttonDown ||
              hd.hoverAction ||
              hd.dragging ||
              (action && (action.onHover || action.onHoverDrag))
            ) {
              hd.mdX = md.x
              hd.mdY = md.y
              hd.shiftKey = ev.shiftKey
              hd.altKey = ev.altKey
              hd.ctrlKey = ev.ctrlKey
              if (hd.dragging) {
                hd.dragging(hd.mdX, hd.mdY, true)
              }
              requestAnimationFrame(redrawThis)
            }
          },
          { passive: true }
        )

        canvas.addEventListener(
          'mouseout',
          (_ev) => {
            const hd = this.hd
            hd.mdX = hd.mdY = null
            requestAnimationFrame(redrawThis)
          },
          { passive: true }
        )

        canvas.addEventListener(
          'mouseover',
          (_ev) => {
            requestAnimationFrame(redrawThis)
          },
          { passive: true }
        )

        canvas.addEventListener('mouseup', (ev) => {
          const hd = this.hd
          const oldMdX = hd.mdX
          const oldMdY = hd.mdY
          hd.mdX = hd.mdY = null
          hd.shiftKey = ev.shiftKey
          hd.altKey = ev.altKey
          hd.ctrlKey = ev.ctrlKey
          hd.buttonDown = false
          const md = eventOffsets(ev)
          const action = hd.find(md.x, md.y)
          if (action && action.onClick) {
            action.onClick()
          }
          if (action && action.onUp) {
            action.onUp()
          }
          if (hd.dragging) {
            hd.dragging(oldMdX, oldMdY, false)
            hd.dragging = null
          }
          requestAnimationFrame(redrawThis)
        })

        canvas.addEventListener(
          'touchstart',
          (ev) => {
            const hd = this.hd
            if (ev.touches.length === 1) {
              const md = {
                x: ev.touches[0].clientX,
                y: ev.touches[0].clientY
              }
              const action = hd.findLoose(md.x, md.y) || hd.defaultActions
              if (
                action &&
                (action.onDown ||
                  action.onClick ||
                  action.onUp ||
                  action.drawDown ||
                  action.drawCustom)
              ) {
                hd.buttonDown = true
                hd.mdX = md.x
                hd.mdY = md.y
                if (action.onDown) {
                  action.onDown(hd.mdX, hd.mdY, ev)
                }
              }
            }
            requestAnimationFrame(redrawThis)
          },
          { passive: true }
        )

        canvas.addEventListener(
          'touchmove',
          (ev) => {
            const hd = this.hd
            if (ev.touches.length === 1) {
              const md = {
                x: ev.touches[0].clientX,
                y: ev.touches[0].clientY
              }
              const action = hd.find(md.x, md.y)
              if (
                hd.buttonDown ||
                hd.hoverAction ||
                hd.dragging ||
                (action && (action.onHover || action.onHoverDrag))
              ) {
                hd.mdX = md.x
                hd.mdY = md.y
                if (hd.dragging) {
                  hd.dragging(hd.mdX, hd.mdY, true)
                }
              }
              requestAnimationFrame(redrawThis)
            }
          },
          { passive: true }
        )

        canvas.addEventListener(
          'touchend',
          (ev) => {
            const hd = this.hd
            const oldMdX = hd.mdX
            const oldMdY = hd.mdY
            hd.mdX = hd.mdY = null
            hd.buttonDown = false
            const action = hd.find(oldMdX, oldMdY)
            if (action && action.onClick) {
              action.onClick()
            }
            if (action && action.onUp) {
              action.onUp()
            }
            if (hd.dragging) {
              hd.dragging(oldMdX, oldMdY, false)
              hd.dragging = null
            }
            requestAnimationFrame(redrawThis)
          },
          { passive: true }
        )
      }

      redrawCanvas () {
        let ctx = this.canvas.getContext('2d')
        const devicePixelRatio = window.devicePixelRatio || 1
        const backingStoreRatio =
          ctx.webkitBackingStorePixelRatio ||
          ctx.mozBackingStorePixelRatio ||
          ctx.msBackingStorePixelRatio ||
          ctx.oBackingStorePixelRatio ||
          ctx.backingStorePixelRatio ||
          1
        const ratio = devicePixelRatio / backingStoreRatio
        this.canvas.pixelRatio = ratio
        const cssWidth = this.canvas.clientWidth
        const cssHeight = this.canvas.clientHeight
        if (0) console.log('autoSize', cssWidth, cssHeight)
        const canvasPixelWidth = Math.min(5000, Math.floor(cssWidth * ratio))
        const canvasPixelHeight = Math.min(5000, Math.floor(cssHeight * ratio))
        if (
          canvasPixelWidth !== this.canvas.width ||
          canvasPixelHeight !== this.canvas.height
        ) {
          console.log(`Resize canvas ${canvasPixelWidth}x${canvasPixelHeight}`)
          this.canvas.width = canvasPixelWidth
          this.canvas.height = canvasPixelHeight
          ctx = this.canvas.getContext('2d') // refetch context
        }
        if (!ctx) {
          throw new Error(
            `Failed to create context ${this.o.contextStyle || '2d'}`
          )
        }
        const pixelRatio = this.canvas.pixelRatio || 1
        ctx.save()
        ctx.resetTransform()
        ctx.scale(pixelRatio, pixelRatio)

        ctx.curLayer = (f) => f()
        ctx.textLayer = canvasutils.mkDeferQ()
        ctx.buttonLayer = canvasutils.mkDeferQ()
        ctx.cursorLayer = canvasutils.mkDeferQ()
        ctx.tooltipLayer = canvasutils.mkDeferQ()
        this.hd.beginDrawing(ctx)
        const cw = this.canvas.width / pixelRatio
        const ch = this.canvas.height / pixelRatio
        const lo = new BoxLayout(0, cw, ch, 0, pixelRatio, {})

        if (0) {
          console.log({
            devicePixelRatio,
            backingStoreRatio,
            ratio,
            canvasPixelWidth,
            canvasPixelHeight,
            cw,
            ch,
            lo
          })
        }

        if (this.bgFillStyle) {
          ctx.fillStyle = this.bgFillStyle
          ctx.fillRect(0, 0, cw, ch)
        } else {
          ctx.clearRect(0, 0, cw, ch)
        }
        ctx.lineWidth = 1.0
        ctx.strokeStyle = '#000000'
        ctx.fillStyle = '#000000'

        this.draw(ctx, lo, this.hd)

        ctx.textLayer.now()
        ctx.buttonLayer.now()
        ctx.cursorLayer.now()

        // Hover actions are often set in the button layer, and often add tooltips so this goes between them.
        if (this.hd.hoverAction) {
          this.hd.hoverAction(this.hd.mdX, this.hd.mdY)
        }
        ctx.tooltipLayer.now()
        ctx.textLayer =
          ctx.buttonLayer =
          ctx.cursorLayer =
          ctx.tooltipLayer =
          ctx.curLayer =
            null // GC paranoia
        this.hd.endDrawing()
        if (this.hd.dragCursor && this.hd.dragging) {
          // see https://developer.mozilla.org/en-US/docs/Web/CSS/cursor?redirectlocale=en-US&redirectslug=CSS%2Fcursor
          // Grab not supported on IE or Chrome/Windows
          this.canvas.style = `cursor: ${this.hd.dragCursor}`
        } else if (this.hd.hoverCursor) {
          this.canvas.style = `cursor: ${this.hd.hoverCursor}`
        } else {
          this.canvas.style = 'cursor: default'
        }

        ctx.restore()
      }
    }

    function parseColor (c) {
      let m = c.match(
        /^rgba\s*\(\s*(\d+)\s*,\s*(\d+)\s*,\s*(\d+)\s*,\s*(\d+)\s*\)$/i
      )
      if (m) {
        return [
          parseFloat(m[1]),
          parseFloat(m[2]),
          parseFloat(m[3]),
          parseFloat(m[4])
        ]
      }

      m = c.match(/^rgb\s*\(\s*(\d+)\s*,\s*(\d+)\s*,\s*(\d+)\s*\)$/i)
      if (m) {
        return [parseFloat(m[1]), parseFloat(m[2]), parseFloat(m[3]), 255]
      }
      return null
    }

    class GrowthViewCanvas extends BaseCanvas {
      constructor (canvas, m, o) {
        super(canvas)
        this.m = m
        this.o = o
        this.setupColors()
      }

      setupColors () {
        this.canvasBgColor = parseColor(this.canvasStyle.backgroundColor) || [
          255, 255, 255, 255
        ]
        console.log('canvasBgColor', this.canvasBgColor)
      }

      draw (ctx, lo, hd) {
        const m = this.m

        /*
      Draw everything in the canvas (through ctx), and associate callbacks with clickable
      or draggable areas.

      This gets called whenever something changes (but by using requestAnimationFrame,
      only once per screen refresh) and it redraws everything from scratch.

      m: an OomModel
      ctx: an HTML5 2D canvas rendering context.
      hd: a HitDetector (from tlbcore)
      lo: a layout object, containing at least {boxL, boxT, boxR, boxB} for the canvas dimensions
      o: options, not used here
    */

        setupLayout()
        drawTitle()
        drawInstructions()
        drawAxes()
        drawCapital()
        drawExp()
        drawRev()
        drawBreakeven()
        drawIpo()
        drawXLabels()
        drawYLabels()

        function setupLayout () {
          /*
        Leave some margins around the plot for axis labels etc.
      */
          lo.labelW = 70
          lo.plotL = lo.boxL + 0.0 * (lo.boxR - lo.boxL) + 5 + lo.labelW
          lo.plotR = lo.boxR - 20
          lo.plotT = lo.boxT + 45
          lo.plotB = lo.boxB - 30
          lo.dragRad = 6

          /*
        These functions, which had better be correct inverses of each other, define the X and Y scaling of the plot
      */
          lo.convWeekToX = function (week) {
            return (week / m.nWeeks) * (lo.plotR - lo.plotL) + lo.plotL
          }
          lo.convXToWeek = function (x) {
            return ((x - lo.plotL) / (lo.plotR - lo.plotL)) * m.nWeeks
          }
          lo.convFlowToY = function (flow) {
            return (
              ((Math.log(flow) - Math.log(m.minFlow)) /
                (Math.log(m.maxFlow) - Math.log(m.minFlow))) *
                (lo.plotT - lo.plotB) +
              lo.plotB
            )
          }
          lo.convYToFlow = function (y) {
            return Math.exp(
              ((y - lo.plotB) / (lo.plotT - lo.plotB)) *
                (Math.log(m.maxFlow) - Math.log(m.minFlow)) +
                Math.log(m.minFlow)
            )
          }
        }

        function drawAxes () {
          ctx.strokeStyle = '#cccccc'
          ctx.lineWidth = lo.thinWidth
          ctx.beginPath()
          ctx.moveTo(lo.plotL, lo.plotB)
          ctx.lineTo(lo.plotR, lo.plotB)
          ctx.stroke()
        }

        function drawXLabels () {
          ctx.font = '12px Arial'
          for (
            let week = 0, year = 0;
            week <= m.nWeeks;
            week += weeksPerYear, year += 1
          ) {
            const label = 'year ' + year.toString()
            const weekX = lo.convWeekToX(week)

            ctx.beginPath()
            ctx.moveTo(weekX, lo.plotB)
            ctx.lineTo(weekX, lo.plotB + 7)

            ctx.strokeStyle = '#888888'
            ctx.lineWidth = lo.thinWidth
            ctx.stroke()
            ctx.textAlign = 'center'
            ctx.textBaseline = 'top'
            ctx.fillText(label, weekX, lo.plotB + 10)
          }
        }

        function drawYLabels () {
          ctx.font = 'bold 12px Arial'
          ctx.textAlign = 'center'
          ctx.textBaseline = 'bottom'
          ctx.fillText('Revenue/Expense', lo.plotL, lo.plotT - 30)

          ctx.beginPath()
          ctx.moveTo(lo.plotL, lo.plotB)
          ctx.lineTo(lo.plotL, lo.plotT)
          ctx.moveTo(lo.weeklyAxisR, lo.plotB)
          ctx.lineTo(lo.weeklyAxisR, lo.plotT)
          ctx.strokeStyle = '#cccccc'
          ctx.lineWidth = lo.thinWidth
          ctx.stroke()

          ctx.font = '12px Arial'
          ctx.textBaseline = 'middle'
          const flows = getFlows(m.units)
          for (const flow of flows) {
            if (flow <= m.maxFlow) {
              const label = fmtFlow(flow, m.units, 2, false)
              const flowY = lo.convFlowToY(flow)

              ctx.beginPath()
              ctx.moveTo(lo.plotL, flowY)
              ctx.lineTo(lo.plotL - 10, flowY)
              ctx.strokeStyle = '#888888'
              ctx.lineWidth = lo.thinWidth
              ctx.stroke()
              ctx.textAlign = 'right'
              ctx.textBaseline = 'middle'
              ctx.fillText(label, lo.plotL - 12, flowY)
            }
          }
          ctx.font = 'bold 12px Arial'
          ctx.textAlign = 'right'
          ctx.textBaseline = 'bottom'
          const label = fmtUnits(m.units)
          ctx.fillText(label, lo.plotL - 4, lo.plotT - 8)

          ctx.font = 'bold 12px Arial'
          ctx.textAlign = 'left'
          ctx.textBaseline = 'bottom'
          const changeLabel = 'change'
          const changeLabelW = ctx.measureText(changeLabel).width
          hd.add(
            lo.plotT - 20,
            lo.plotL + changeLabelW + 10,
            lo.plotT - 5,
            lo.plotL,
            {
              drawCustom: (hover) => {
                if (hover) {
                  ctx.fillStyle = '#ee0000'
                } else {
                  ctx.fillStyle = '#5555ff'
                }
                ctx.fillText(changeLabel, lo.plotL + 5, lo.plotT - 8)
              },
              onClick: (mdX, mdY) => {
                m.toggleUnits()
                m.emit('stablized')
              }
            }
          )
        }

        function drawCapital () {
          if (m.breakevenWeek > 0) {
            const p0X = lo.convWeekToX(0)
            const p0Y = lo.convFlowToY(m.rev0)
            const p1X = lo.convWeekToX(0)
            const p1Y = lo.convFlowToY(m.exp0)
            const p2X = lo.convWeekToX(m.breakevenWeek)
            const p2Y = lo.convFlowToY(m.breakevenFlow)

            ctx.beginPath()
            ctx.moveTo(p0X, p0Y)
            ctx.lineTo(p1X, p1Y)
            ctx.lineTo(p2X, p2Y)

            if (1) {
              const pat = ctx.createLinearGradient(
                lo.plotL,
                lo.plotB,
                lo.plotL,
                lo.plotT
              )
              pat.addColorStop(0.0, 'rgba(200,200,255,0.3)')
              pat.addColorStop(1.0, 'rgba(200,200,255,0.9)')
              ctx.fillStyle = pat
            } else {
              ctx.fillStyle = 'rgba(200,200,255,0.3)'
            }
            ctx.fill()
          }
          ctx.font = 'bold 15px Arial'
          const label =
            m.capitalNeeded >= 0
              ? fmtMoney(m.capitalNeeded, 3) + ' capital needed'
              : 'Infinite capital needed'
          // let labelW = ctx.measureText(label).width;
          const lbWeek =
            m.breakevenWeek > 0 ? Math.min(20, m.breakevenWeek / 4) : 20
          const lbX = lo.plotL + 15
          const lbY =
            (lo.convFlowToY(m.revAtWeek(lbWeek)) +
              2 * lo.convFlowToY(m.expAtWeek(lbWeek))) /
            3

          ctx.fillStyle = '#000000'
          ctx.textAlign = 'left'
          ctx.textBaseline = 'middle'
          ctx.fillText(label, lbX, lbY)
        }

        function drawRev () {
          const p0X = lo.convWeekToX(0)
          const p0Y = lo.convFlowToY(m.rev0)
          const p1Week = Math.max(10, Math.min(m.nWeeks, m.ipoWeek))
          const p1X = lo.convWeekToX(p1Week)
          const p1Y = lo.convFlowToY(m.revAtWeek(p1Week))

          const p01Len = Math.sqrt(
            Math.pow(p1X - p0X, 2) + Math.pow(p1Y - p0Y, 2)
          )
          const protRad = Math.max(
            0,
            Math.max((lo.plotB - lo.plotT) * 0.6, (lo.plotR - lo.plotL) * 0.48)
          ) // radius of our protractor
          const pmX = p0X + ((p1X - p0X) * protRad) / p01Len
          const pmY = p0Y + ((p1Y - p0Y) * protRad) / p01Len

          ctx.textLayer(() => {
            ctx.strokeStyle = '#000000'
            ctx.lineWidth = lo.thinWidth
            const growthRates = getGrowthRates(m.units)
            ctx.textAlign = 'left'
            ctx.textBaseline = 'middle'
            ctx.fillStyle = '#000000'
            ctx.font = '12px Arial'
            let maxAngle = 0
            for (const growthRate of growthRates) {
              const p3X = lo.convWeekToX(p1Week)
              const p3Y = lo.convFlowToY(
                Math.exp(m.rev0Log + Math.log(1 + growthRate) * p1Week)
              )
              const angle = Math.atan2(p3Y - p0Y, p3X - p0X)
              maxAngle = Math.min(maxAngle, angle)
              // let p03Len = Math.sqrt(Math.pow(p3X-p0X, 2) + Math.pow(p3Y-p0Y, 2));
              ctx.save()
              ctx.translate(p0X, p0Y)
              ctx.rotate(angle)
              ctx.moveTo(protRad + 0, 0)
              ctx.lineTo(protRad + 10, 0)
              ctx.stroke()
              const label = fmtGrowth(growthRate, m.units, 2, false)
              ctx.fillText(label, protRad + 12, 0)
              ctx.restore()
            }
            maxAngle -= 0.03 // radians
            ctx.beginPath()
            if (0) ctx.moveTo(p0X + protRad * 0.5, p0Y)
            ctx.arc(p0X, p0Y, protRad, 0, maxAngle, true)
            ctx.stroke()
          })
          ctx.beginPath()
          ctx.moveTo(p0X, p0Y)
          ctx.lineTo(p1X, p1Y)
          ctx.strokeStyle = '#aaf5aa'
          ctx.lineWidth = 3
          ctx.stroke()

          ctx.buttonLayer(() => {
            const angle = Math.atan2(pmY - p0Y, pmX - p0X)
            ctx.save()
            ctx.translate(p0X, p0Y)
            ctx.rotate(angle)

            ctx.textAlign = 'right'
            ctx.textBaseline = 'middle'
            ctx.font = 'bold 15px Arial'

            const label = 'growing ' + fmtGrowth(m.revGrowth, m.units, 3, true)
            const labelW = ctx.measureText(label).width

            if (0) {
              ctx.beginPath()
              canvasutils.drawRountangle(
                ctx,
                protRad - 24 - labelW,
                -8,
                protRad,
                +8,
                5
              )
              ctx.fillStyle = 'rgba(255,255,255,0.3)'
              ctx.fill()
            }

            ctx.fillStyle = '#000000'
            ctx.fillText(label, protRad - 12, 0)
            ctx.restore()
          })

          ctx.buttonLayer(() => {
            const angle = Math.atan2(pmY - p0Y, pmX - p0X)
            ctx.save()
            ctx.translate(p0X, p0Y)
            ctx.rotate(angle)

            ctx.font = 'bold 15px Arial'
            ctx.textAlign = 'left'
            ctx.textBaseline = 'middle'

            const label = fmtFlow(m.rev0, m.units, 3, true) + ' revenue'
            const labelW = ctx.measureText(label).width

            if (0) {
              ctx.beginPath()
              canvasutils.drawRountangle(ctx, 10, -8, 20 + labelW, +8, 5)
              ctx.fillStyle = 'rgba(255,255,255,0.3)'
              ctx.fill()
            }

            ctx.fillStyle = '#000000'
            ctx.fillText(label, 15, 0)
            ctx.restore()
          })

          hd.add(
            p0Y - lo.dragRad,
            p0X + lo.dragRad,
            p0Y + lo.dragRad,
            p0X - lo.dragRad,
            {
              draw: function () {
                drawDragHandle(ctx, p0X, p0Y, lo.dragRad, 'rev')
              },
              onDown: function (mdX, mdY) {
                hd.dragging = function (dragX, dragY, isDown) {
                  if (!isDown) {
                    m.emit('stablized')
                    return
                  }
                  const newRev = lo.convYToFlow(dragY)
                  m.setRevAtWeek(0, newRev)
                  m.everDragged = true
                }
              },
              onUp: function () {
                m.emit('stablized')
              },
              onHover: function () {
                canvasutils.drawTooltip(
                  ctx,
                  lo,
                  p0X,
                  p0Y,
                  'Drag to change initial revenue'
                )
              }
            }
          )

          hd.add(
            pmY - lo.dragRad,
            pmX + lo.dragRad,
            pmY + lo.dragRad,
            pmX - lo.dragRad,
            {
              draw: function () {
                drawDragHandle(ctx, pmX, pmY, lo.dragRad, 'rev')
              },
              onDown: function (mdX, mdY) {
                hd.dragging = function (dragX, dragY, isDown) {
                  if (!isDown) {
                    m.emit('stablized')
                    return
                  }
                  const newWeek = lo.convXToWeek(dragX)
                  const newRev = lo.convYToFlow(dragY)
                  m.setRevAtWeek(newWeek, newRev)
                  m.everDragged = true
                }
              },
              onHover: function () {
                canvasutils.drawTooltip(
                  ctx,
                  lo,
                  pmX,
                  pmY,
                  'Drag to change revenue growth rate'
                )
              }
            }
          )
        }

        function drawExp () {
          const p0X = lo.convWeekToX(0)
          const p0Y = lo.convFlowToY(m.exp0)
          const p1Week = m.nWeeks
          const p1X = lo.convWeekToX(p1Week)
          const p1Y = lo.convFlowToY(m.expAtWeek(p1Week))

          ctx.beginPath()
          ctx.moveTo(p0X, p0Y)
          ctx.lineTo(p1X, p1Y)
          ctx.strokeStyle = '#f5bbbb'
          ctx.lineWidth = 3
          ctx.stroke()

          ctx.buttonLayer(() => {
            const angle = Math.atan2(p1Y - p0Y, p1X - p0X)
            ctx.save()
            ctx.translate(p0X, p0Y)
            ctx.rotate(angle)

            ctx.font = 'bold 15px Arial'
            ctx.textAlign = 'left'
            ctx.textBaseline = 'middle'

            const label = fmtFlow(m.exp0, m.units, 3, true) + ' expense'
            const labelW = ctx.measureText(label).width

            if (0) {
              ctx.beginPath()
              canvasutils.drawRountangle(ctx, -8, 20 + labelW, 8, 10, 5)
              ctx.fillStyle = 'rgba(255,255,255,0.5)'
              ctx.fill()
            }

            ctx.fillStyle = '#000000'
            ctx.fillText(label, 15, 0)
            ctx.restore()
          })

          hd.add(
            p0Y - lo.dragRad,
            p0X + lo.dragRad,
            p0Y + lo.dragRad,
            p0X - lo.dragRad,
            {
              draw: function () {
                drawDragHandle(ctx, p0X, p0Y, lo.dragRad, 'exp')
              },
              onDown: function (mdX, mdY) {
                hd.dragging = function (dragX, dragY, isDown) {
                  if (!isDown) {
                    m.emit('stablized')
                    return
                  }
                  const newExp = lo.convYToFlow(dragY)
                  m.setExpAtWeek(0, newExp)
                  m.everDragged = true
                }
              },
              onHover: function () {
                canvasutils.drawTooltip(
                  ctx,
                  lo,
                  p0X,
                  p0Y,
                  'Drag to change initial expense'
                )
              }
            }
          )
        }

        function drawBreakeven () {
          if (m.breakevenWeek < 0 || m.breakevenWeek > 30 * 52) return

          const label = 'Profitable at ' + fmtTime(m.breakevenWeek, 'year', 1)
          const drawArrow = lo.convWeekToX(m.breakevenWeek) > lo.plotR

          if (drawArrow) {
            const p0X = lo.plotR
            const p0Y = Math.min(
              lo.convFlowToY(m.breakevenFlow),
              lo.convFlowToY(m.expN) - 10
            )
            const p1X = lo.plotR - 20
            const p1Y = Math.min(p0Y, lo.convFlowToY(m.expN) - 10)

            ctx.moveTo(p0X, p0Y)
            ctx.lineTo(p1X, p1Y)
            ctx.moveTo(p0X, p0Y)
            ctx.lineTo(p0X - 8, p0Y - 3)
            ctx.moveTo(p0X, p0Y)
            ctx.lineTo(p0X - 8, p0Y + 3)
            ctx.strokeStyle = '#888888'
            ctx.lineWidth = 1
            ctx.stroke()

            ctx.fillStyle = '#000000'
            ctx.font = '15px Arial'

            ctx.textBaseline = 'middle'
            ctx.textAlign = 'right'
            ctx.fillText(label, p1X - 5, p1Y)
          } else {
            const p0X = lo.convWeekToX(m.breakevenWeek)
            const p0Y = lo.convFlowToY(m.breakevenFlow)
            const p1X = lo.convWeekToX(m.breakevenWeek)
            const p1Y = p0Y + 20 // lo.plotB;

            ctx.moveTo(p0X, p0Y)
            ctx.lineTo(p1X, p1Y)
            ctx.strokeStyle = '#888888'
            ctx.lineWidth = 1
            ctx.stroke()

            ctx.font = '15px Arial'
            const labelSize = ctx.measureText(label)
            const labelW = labelSize.width
            const labelH =
              labelSize.actualBoundingBoxDescent +
              labelSize.actualBoundingBoxAscent
            if (p1X + labelW + 10 > lo.plotR) {
              ctx.textLayer(() => {
                ctx.font = '15px Arial'
                ctx.fillStyle = '#ffffff88'
                ctx.fillRect(
                  p1X - 2 - labelW,
                  p1Y + 1,
                  labelW + 10,
                  labelH + 10
                )
                ctx.textBaseline = 'top'
                ctx.textAlign = 'right'
                ctx.fillStyle = '#000000'
                ctx.fillText(label, p1X + 3, p1Y + 1)
              })
            } else {
              ctx.textLayer(() => {
                ctx.font = '15px Arial'
                ctx.fillStyle = '#ffffff88'
                ctx.fillRect(p1X - 8, p1Y - 4, labelW + 10, labelH + 10)
                ctx.textBaseline = 'top'
                ctx.textAlign = 'left'
                ctx.fillStyle = '#000000'
                ctx.fillText(label, p1X - 3, p1Y + 1)
              })
            }
          }
        }

        function drawIpo () {
          if (m.ipoWeek < 0 || m.ipoWeek > 100 * 52) return

          const drawArrow = lo.convWeekToX(m.ipoWeek) > lo.plotR
          const p0X = drawArrow ? lo.plotR : lo.convWeekToX(m.ipoWeek)
          const p0Y = lo.convFlowToY(m.revAtWeek(m.ipoWeek))
          const label = `$1B/yr revenue at ${fmtTime(m.ipoWeek, 'year', 1)}`
          const p1X = Math.min(lo.plotR - 20, p0X - 20)
          const p1Y = p0Y

          ctx.moveTo(p0X, p0Y)
          ctx.lineTo(p1X, p1Y)
          if (drawArrow) {
            ctx.moveTo(p0X, p0Y)
            ctx.lineTo(p0X - 8, p0Y - 3)
            ctx.moveTo(p0X, p0Y)
            ctx.lineTo(p0X - 8, p0Y + 3)
          }
          ctx.strokeStyle = '#888888'
          ctx.lineWidth = 1
          ctx.stroke()

          ctx.fillStyle = '#000000'
          ctx.font = '15px Arial'
          ctx.textBaseline = 'middle'
          ctx.textAlign = 'right'
          ctx.fillText(label, p1X - 5, p1Y)
        }

        function drawTitle () {
          const cX = (lo.plotL + lo.plotR) / 2
          const lY = lo.boxT + 2
          const title = 'Startup Growth Calculator'
          ctx.font = 'bold 20px Arial'
          ctx.fillStyle = '#000000'
          ctx.textBaseline = 'top'
          ctx.textAlign = 'center'
          ctx.fillText(title, cX, lY)
        }

        function drawInstructions () {
          if (!(m.showInstructions > 0)) return
          const cX = (lo.plotL + lo.plotR) / 2
          const lY = lo.plotT + 100

          ctx.save()
          ctx.globalAlpha = (1 - Math.cos(m.showInstructions * Math.PI)) / 2

          ctx.font = '25px Arial'
          const lines = [
            'How much money will you burn before your startup is profitable?',
            'Drag the red and green handles to change expense and revenue',
            "The shaded blue area shows how much money you'll need"
          ]
          let linesW = 100
          for (const line of lines) {
            linesW = Math.max(linesW, ctx.measureText(line).width)
          }

          ctx.beginPath()
          canvasutils.drawRountangle(
            ctx,
            lY - 30,
            cX + linesW / 2 + 20,
            lY + (lines.length - 1) * 35 + 30,
            cX - linesW / 2 - 20,
            10
          )
          ctx.fillStyle = '#ffcc66'
          ctx.fill()

          ctx.fillStyle = '#000000'
          ctx.textBaseline = 'middle'
          ctx.textAlign = 'center'
          for (let linei = 0; linei < lines.length; linei++) {
            ctx.fillText(lines[linei], cX, lY + linei * 35)
          }

          ctx.restore()
        }
      }
    }

    onMounted(() => {
      const m = new GrowthModel({})
      const canvas = canvasRef.value
      const v = new GrowthViewCanvas(canvas, m, {})
      v.redrawCanvas()
    })

    return {
      canvasRef
    }
  }
}
</script>

<style>
.growthview {
  vertical-align: top;
  width: 100%;
}
.growthfooter {
  height: 40px;
}

.growthvispanel {
  display: inline-block;
  margin: 0 10px 0 10px;
}
.growthpaneltitle {
  margin: 0px;
  font-weight: bold;
}

.growthcanvas {
  width: 100%;
  height: 600px;
  background-color: #ffffffff;
}

.growthabout {
  width: 650px;
  margin: 0 auto;
}
</style>
