<script setup></script>

<template>
  <router-view/>
  <div class="growthabout">
    <p>This tool calculates how much funding your startup needs. Assuming your expenses are constant and your revenue is growing, it shows when you'll reach profitability and how much capital you'll burn through before then. Once you're profitable, you control your destiny: you can raise more to grow faster if you want.</p>
    <p>You can drag the red or green handles to set expense, revenue and growth. Geometrically, the capital needed is the blue-shaded area between the revenue and expense curves.</p>
    <p>If you raised exactly the amount calculated and everything goes as expected, your bank account would be at $0 the month you hit profitability, which is kind of stressful. So raise a comfortable margin above it.</p>
    <div>
      Based On Trevor Blackwell's TLB.ORG
    </div>
  </div>
</template>

<style scoped>
.growthabout {
  width: 900px;
  margin: 0 auto;
}
</style>
